import Our_Mission from '../images/Our_Mission.jpg'
import Our_Vision from '../images/Our_Vision.jpg'
import Our_Values from '../images/Our_Values.jpeg'
import mission_icon from '../icons/mission_icon.png'
import vision_icon from '../icons/vision_icon.png'
import values_icon from '../icons/values_icon.jpg'
import { Our_Values_Data } from './Data'

function AboutUs() {
    return (
        <>
            {/* Mobile */}
            <div className='md:hidden '>
                <h1 className='text-center text-[32px] font-bold mb-4'> About 4U</h1>
                {/* Our Mission Card */}
                <div className='rounded-3xl flex flex-col justify-center bg-black opacity-80 mb-4'>
                    <div className='flex justify-center gap-5 mt-5 mb-10 mx-4'>
                        <h1 className='text-[32px] text-white font-bold'> Our Mission </h1>
                        <img className='w-[74.46px] h-[50px]' src={mission_icon} />
                    </div>
                    <h1 className='text-white text-[16px] mx-4 mb-10'>Importing plastic raw materials for factories, producing HDPE and PPR pipes, supplying Ethiopian spices, extracting black mint and oils, exporting finished gemstones, and ensuring market stability.</h1>
                    <img className='h-[300px] rounded-3xl' src={Our_Mission} />
                </div>

                {/* Our Vision Card */}
                <div className='rounded-3xl flex flex-col justify-center bg-black opacity-80 mb-4'>
                    <div className='flex justify-center gap-5 mt-5 mb-10 mx-4'>
                        <h1 className='text-[32px] text-white font-bold'> Our Vision </h1>
                        <img className='w-[74.46px] h-[50px]' src={vision_icon} />
                    </div>
                    <h1 className='text-white text-[16px] mx-4 mb-10'>To be profitable by providing a preferred, fast and efficient service that is competitive at the international level, with the help of qualified professionals and procedures using up-to-date technology and working for social change.</h1>
                    <img className='h-[300px] rounded-3xl' src={Our_Vision} />
                </div>

                {/* Our Values Card */}
                <div className='rounded-3xl flex flex-col justify-center bg-black opacity-80 mb-4'>
                    <div className='flex justify-center gap-5 mt-5 mb-10 mx-4'>
                        <h1 className='text-[32px] text-white font-bold'> Our Values </h1>
                        <img className='w-[74.46px] h-[50px]' src={values_icon} />
                    </div>
                    <div className='flex flex-col mb-10 '>
                        {Our_Values_Data.map((item) => (
                            <div className='flex items-center mx-4 py-1 gap-5'>
                                <img className='h-6' src={item.img} alt='' />
                                <p className='text-white '>{item.text}</p>
                            </div>
                        ))}
                    </div>
                    <img className='h-[300px] rounded-3xl' src={Our_Values} />
                </div>
            </div>
            {/* Desktop */}
            <div className='hidden md:block'>
                <h1 className='text-center text-3xl font-bold mb-4'> About 4U</h1>
                {/* Our Mission Card */}
                <div className='mx-20 rounded-3xl flex flex-col justify-center bg-black opacity-80 mb-4'>
                    <div className='flex justify-start items-center'>
                        <img className='w-[90rem] h-[25rem] rounded-3xl' src={Our_Mission} />
                        <div className='flex flex-col items-center '>
                            <div className='flex justify-center items-center gap-10'>
                                <h1 className='text-5xl text-white font-bold'> Our Mission </h1>
                                <img className='h-36' src={mission_icon} />
                            </div>
                            <h1 className='text-white w-5/6'>"Importing plastic raw materials for factories, producing HDPE and PPR pipes, supplying Ethiopian spices, extracting black mint and oils, exporting finished gemstones, and ensuring market stability."</h1>
                        </div>
                    </div>
                </div>

                {/* Our Vision Card */}
                <div className='mx-20 rounded-3xl flex flex-col justify-center bg-black opacity-80 mb-4'>
                    <div className='flex justify-start items-center'>
                        <div className='flex flex-col items-center '>
                            <div className='flex justify-center items-center gap-10'>
                                <h1 className='text-5xl text-white font-bold'> Our Vision </h1>
                                <img className='h-36' src={vision_icon} />
                            </div>
                            <h1 className='text-white w-5/6'>"To be profitable by providing a preferred, fast and efficient service that is competitive at the international level, with the help of qualified professionals and procedures using up-to-date technology and working for social change."</h1>
                        </div>
                        <img className='w-[90rem] h-[25rem] rounded-3xl' src={Our_Vision} />
                    </div>
                </div>

                {/* Our Values Card */}
                <div className='mx-20 rounded-3xl flex flex-col justify-center bg-black opacity-80 mb-4'>
                    <div className='flex justify-start items-center'>
                        <div className='w-[90rem] h-[25rem] overflow-hidden'>
                            <img className='w-full h-full rounded-3xl object-cover ' src={Our_Values} />
                        </div>
                        <div className='flex flex-col items-center '>
                            <div className='flex justify-center items-center gap-10 '>
                                <h1 className='text-5xl text-white font-bold '> Our Values </h1>
                                <img className='h-28' src={values_icon} />
                            </div>
                            <div className='flex flex-col w-[33rem]'>
                                {Our_Values_Data.map((item) => (
                                    <div className='flex items-center mx-16 py-1 gap-2'>
                                        <img className='h-6' src={item.img} alt='' />
                                        <p className='text-white '>{item.text}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='bg-gray-300 w-full h-0.5 my-10' />
            </div>
        </>
    );
}

export default AboutUs