import { footerSocialMediaData } from './Data';
import cr from '../images/CR.pdf';
import vat from '../images/Vat_Regestration.pdf';

function Footer() {
    return (
        <footer className="bg-main h-full w-full p-5 md:p-10">
            <div className="flex flex-col md:flex-row justify-between items-center gap-10">
                {/* Mobile & Email */}
                <div className="flex flex-col text-white items-center md:items-start">
                    <h1 className="text-2xl font-bold mb-2 text-center md:text-left">4U PLC Co.</h1>
                    <p className="text-center md:text-left">+251 94 431 4854</p>
                    <p className="text-center md:text-left">info@forutrading.com</p>
                </div>

                {/* Pages & Social Media */}
                <div className="flex flex-col gap-4 items-center">
                    <div className="flex flex-wrap justify-center text-white gap-5 md:gap-10">
                        <a href="#contactUs">Contact Us</a>
                        <a href="#Services">Our Services</a>
                        <a href="#AboutUs">About Us</a>
                        <a href="/">Home</a>
                    </div>
                    <div className="flex justify-center gap-4 items-center">
                        {footerSocialMediaData.map((item) => (
                            <a href={item.link} key={item.link}>
                                <img className="h-10 w-10 md:h-12 md:w-12" src={item.icon} alt="social-media-icon" />
                            </a>
                        ))}
                    </div>
                </div>

                {/* Attachments */}
                <div className="flex flex-col justify-center items-center">
                    <h1 className="text-2xl font-bold text-white mb-2">Attachments</h1>
                    <a
                        download="4U CR"
                        href={cr}
                        className="w-full rounded-2xl py-2 px-4 bg-white text-main text-center mb-2"
                    >
                        View CR
                    </a>
                    <a
                        download="4U VAT"
                        href={vat}
                        className="w-full rounded-2xl py-2 px-4 bg-main border-2 border-white text-center text-white"
                    >
                        View VAT registration
                    </a>
                </div>
            </div>
            <hr className="my-5 border-white" />
            <h1 className="text-center text-white text-sm md:text-base pb-5">
                All rights reserved to 4U Company © 2025
            </h1>
        </footer>
    );
}

export default Footer;
