import call from '../icons/phone.png'
import mail from '../icons/email.png'
import location from '../icons/location.png'
import emailjs from '@emailjs/browser';
import { useRef } from 'react';

function ContactUs() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ovkwq1e', 'template_2xmv7j5', form.current, 'ua95kkyJoGrecmu7u')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    };

    return (
        <section id="contactUs" className="p-5">
            <div>
                <div className="flex justify-center">
                    <h1 className="text-center font-bold text-lg md:text-xl">
                        We are here <span className="text-white bg-main rounded-md py-1 px-2">For You</span> !!!
                    </h1>
                </div>

                <h1 className="text-center font-bold text-2xl md:text-3xl my-5">
                    Feel Free To Reach Us Any Time
                </h1>

                <div className="flex flex-col lg:flex-row justify-center text-white rounded-xl gap-10 md:gap-8 my-10">

                    <div className="md:group flex flex-col items-center justify-center bg-main w-full max-w-md mx-auto rounded-xl overflow-visible">
                        <img src={call} alt="Call" className="w-20 h-20 md:w-32 md:h-32 p-4 bg-white -translate-y-8 group-hover:-translate-y-16 border-4 border-main rounded-full" />
                        <h1 className="text-center -translate-y-6 md:-translate-y-0">+966 53 146 0944</h1>
                    </div>

                    <div className="md:group flex flex-col items-center  justify-center bg-[#D9D9D9] w-full max-w-md mx-auto rounded-xl overflow-visible">
                        <img src={mail} alt="Mail" className="w-20 h-20 md:w-32 md:h-32 p-4 bg-white -translate-y-8 group-hover:-translate-y-16 border-4 border-[#D9D9D9] rounded-full" />
                        <h1 className="text-center -translate-y-6 md:-translate-y-0 text-main">info@forutrading.com</h1>
                    </div>

                    <div className="md:group flex flex-col items-center justify-center bg-main w-full max-w-md mx-auto rounded-xl overflow-visible">
                        <img src={location} alt="Location" className="w-20 h-20 md:w-32 md:h-32 p-4 bg-white -translate-y-8 group-hover:-translate-y-16 border-4 border-main rounded-full" />
                        <div className='-translate-y-6 md:-translate-y-2'>
                            <h1 className="text-center">Addis Ababa, Ethiopia</h1>
                            <h1 className="text-center">Futiyan Market Centre</h1>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-2 md:px-10">
                    <form ref={form} onSubmit={sendEmail} className="flex flex-col w-full items-center gap-4">
                        <input required className="outline-none focus:border-main text-main border-2 pl-2 rounded-md w-full h-12" placeholder="Full Name" name="from_name" />
                        <input className="outline-none focus:border-main text-main border-2 pl-2 rounded-md w-full h-12" placeholder="Email" name="email" />
                        <input className="outline-none focus:border-main text-main border-2 pl-2 rounded-md w-full h-12" placeholder="Phone" name="phone" />
                        <textarea className="outline-none focus:border-main text-main border-2 pl-2 pt-2 rounded-md w-full h-36" placeholder="Message" name="message"></textarea>
                        <button className="bg-main text-white rounded-md py-1 px-8 w-fit" type="submit" value="Send">Send</button>
                    </form>

                    <iframe className="rounded-xl w-full h-64 md:h-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.3612499104183!2d38.7459791!3d9.030772599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85f10236aacb%3A0x5d3c0d0d5fb0155b!2sNudo%20Market%20Center!5e0!3m2!1sen!2ssa!4v1700964611731!5m2!1sen!2ssa"
                        title='company location' width="500" height="500" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <hr className="my-10" />
            </div>
        </section>
    );
}

export default ContactUs;
