// import md from '../images/md.png'
// import sa from '../images/sa.png'
import profile from '../images/profile.png'

import instaIcon from '../images/instagram_Original_Circle.png'
import facebookIcon from '../images/facebook_Original.png'
import tiktokIcon from '../images/tiktok__Original_Circle.png'
import linkedin from '../icons/linkedin.png'
import tiktok from '../icons/tiktok.png'
import telegram from '../icons/telegram.png'
import youtube from '../icons/youtube.png'
import whatsapp from '../icons/whatsapp.png'
import instagram from '../icons/instagram.png'
import facebook from '../icons/facebook.png'
import twitter from '../icons/twitter-sign.png'
import whatsapp_colored from '../images/whatsapp.png'


import food_left from '../images/food_left.jpeg'
import food_right from '../images/food_right.jpeg'
import food_center from '../images/food_center.jpeg'

import black1 from '../images/black_seed_01.jpeg'
import black2 from '../images/black_seed_02.jpeg'
import black3 from '../images/black_seed_03.jpeg'
import black4 from '../images/black_seed_04.jpeg'
import black5 from '../images/black_seed_05.jpeg'
import black6 from '../images/black_seed_06.jpeg'

import left from '../images/left.jpeg'
import right from '../images/right.jpeg'
import below from '../images/below.jpeg'

import left_furite from '../images/left_furite.jpeg'
import right_furite from '../images/right_furite.jpeg'
import below_furite from '../images/below_furite.jpeg'

import pic1 from '../icons/1quality.png'
import pic2 from '../icons/2equality.png'
import pic3 from '../icons/3computer.png'
import pic4 from '../icons/4effective.png'
import pic5 from '../icons/5customer.png'
import pic6 from '../icons/6relationship.png'
import pic7 from '../icons/7mindset.png'

// Imported Products
import Plastic_raw_material from '../images/Plastic_raw_material.jpg'
import Different_cake_mix_powder from '../images/Different_cake_mix_powder.jpeg'
import Baking_powder from '../images/Baking_powder.jpeg'
import Ready_made_clothes from '../images/Ready_made_clothes.jpeg'
import Construction_materials from '../images/Construction_materials.jpeg'
import Cosmetics from '../images/Cosmetics.jpeg'
import Food_stuff from '../images/Food_stuff.jpeg'
import PPR_and_HDPE_pipe from '../images/PPR_and_HDPE_pipe.jpeg'
import PP_bags_and_mats from '../images/PP_bags_and_mats.jpeg'

const ServicesData = [
]

const ImportedProducts = [
    {
        product_Name: 'Plastic raw material',
        product_Img: Plastic_raw_material,
    },
    {
        product_Name: 'Different cake mix powder',
        product_Img: Different_cake_mix_powder,
    },
    {
        product_Name: 'Baking powder',
        product_Img: Baking_powder,
    },
    {
        product_Name: 'Ready made clothes',
        product_Img: Ready_made_clothes,
    },
    {
        product_Name: 'Construction materials',
        product_Img: Construction_materials,
    },
    {
        product_Name: 'Cosmetics',
        product_Img: Cosmetics,
    },
    {
        product_Name: 'Food stuff',
        product_Img: Food_stuff,
    },
    {
        product_Name: 'PPR and HDPE pipe',
        product_Img: PPR_and_HDPE_pipe,
    },
    {
        product_Name: 'PP bags and mats',
        product_Img: PP_bags_and_mats,
    },

]

const Our_Values_Data = [
    {
        img: pic1,
        text: "Quality service"
    },
    {
        img: pic2,
        text: "Professional ethics"
    },
    {
        img: pic3,
        text: "Accessibility"
    },
    {
        img: pic4,
        text: "Efficiency and effectiveness"
    },
    {
        img: pic5,
        text: "Being customer oriented"
    },
    {
        img: pic6,
        text: "Community partnership"
    },
    {
        img: pic7,
        text: "Learning and development"
    }

]
const TProducts = [
    {
        bigNo: "1-",
        righImage: food_right,
        leftImage: food_left,
        belowImage: food_center,
        title: "Ethiopian spices",
        desc: ""
    },
    {
        bigNo: "2-",
        righImage: black1,
        leftImage: black3,
        belowImage: black2,
        title: "Black seed and black seed oil",
        desc: ""
    },
    {
        bigNo: "3-",
        righImage: black6,
        leftImage: black5,
        belowImage: black4,
        title: "Sesame seed and sesame seed oil",
        desc: ""
    },
    {
        bigNo: "4-",
        righImage: right,
        leftImage: left,
        belowImage: below,
        title: "Precious stones",
        desc: ""
    },
    {
        bigNo: "5-",
        righImage: left_furite,
        leftImage: right_furite,
        belowImage: below_furite,
        title: "Fruits & Vegetables",
        desc: ""
    }

]

const Data = [
    {
        img: profile,
        name: "Salman Abdullah",
        position: "Sales Officer",
        describe: "Yes, I'm here to guarantee the delivery of exceptional services and top-quality products with unyielding dedication and passion.",
        socialMedia: [
            {
                instaIcons: instaIcon,
                instaLink: 'https://www.instagram.com/saalmmnn/',
                facebookIcon: facebookIcon,
                facebookLink: 'https://www.facebook.com/salman.mamo.54',
                // tiktokIcon: tiktokIcon,
                // tiktokLink: "https://tiktok.com",
                whatsappIcon: whatsapp_colored,
                whatsappLink: 'https://wa.me/+251948672996'
            },

        ]
    },
    {
        img: profile,
        name: "Mohammed Nurye",
        position: "CEO",
        describe: "Yes, I'm here to ensure the delivery of exceptional services and top-quality products with unwavering commitment and passion.",
        socialMedia: [
            {
                instaIcons: instaIcon,
                instaLink: 'https://www.instagram.com/mo.nurye/',
                facebookIcon: facebookIcon,
                facebookLink: 'https://www.facebook.com/profile.php?id=100004053522975',
                tiktokIcon: tiktokIcon,
                tiktokLink: "https://www.tiktok.com/@moohaa__2",
                whatsappIcon: whatsapp_colored,
                whatsappLink: 'https://wa.me/+251944314854'
            },
        ]
    },
    {
        img: profile,
        name: "Ahmed Hassen",
        position: "Co-Founder",
        describe: "Yes, I'm here to guarantee the delivery of exceptional services and top-quality products with unyielding dedication and passion.",
        socialMedia: [
            {
                // instaIcons: instaIcon,
                // instaLink: 'https://google.com',
                // facebookIcon: facebookIcon,
                // facebookLink: 'https://facebook.com',
                // tiktokIcon: tiktokIcon,
                // tiktokLink: "https://tiktok.com",
                whatsappIcon: whatsapp_colored,
                whatsappLink: 'https://wa.me/+966553519891'
            },
        ]


    }, {
        img: profile,
        name: "Mohammed Jemal",
        position: "Co-Founder",
        describe: "Yes, I'm here to guarantee the delivery of exceptional services and top-quality products with unyielding dedication and passion.",
        socialMedia: [
            {
                instaIcons: instaIcon,
                instaLink: 'https://www.instagram.com/mjemal728/',
                // facebookIcon: facebookIcon,
                // facebookLink: 'https://facebook.com',
                // tiktokIcon: tiktokIcon,
                // tiktokLink: "https://tiktok.com",
                whatsappIcon: whatsapp_colored,
                whatsappLink: 'https://wa.me/+251911060355'
            },
        ]

    }
];
const footerSocialMediaData = [
    {
        icon: linkedin,
        link: "https://www.google.com/"
    },
    {
        icon: tiktok,
        link: "https://www.google.com/"
    },
    {
        icon: telegram,
        link: "https://www.google.com/"
    },
    {
        icon: youtube,
        link: "https://www.google.com/"
    },
    {
        icon: whatsapp,
        link: "https://www.google.com/"
    },
    {
        icon: instagram,
        link: "https://www.google.com/"
    },
    {
        icon: facebook,
        link: "https://www.google.com/"
    },
    {
        icon: twitter,
        link: "https://www.google.com/"
    },
]

export { Data, ImportedProducts, footerSocialMediaData, TProducts, Our_Values_Data }