import export_right from '../icons/export_right.png'
import export_left from '../icons/export_left.png'
import eth_logo from '../images/eth_logo.jpeg'
import Plastic_raw_material from '../images/Plastic_raw_material.jpg'
import Different_cake_mix_powder from '../images/Different_cake_mix_powder.jpeg'
import Baking_powder from '../images/Baking_powder.jpeg'
import Ready_made_clothes from '../images/Ready_made_clothes.jpeg'
import Construction_materials from '../images/Construction_materials.jpeg'
import Cosmetics from '../images/Cosmetics.jpeg'
import Food_stuff from '../images/Food_stuff.jpeg'
import PPR_and_HDPE_pipe from '../images/PPR_and_HDPE_pipe.jpeg'
import PP_bags_and_mats from '../images/PP_bags_and_mats.jpeg'
import left_arrow_white from '../icons/left_arrow_white.png'
import left_arrow_gray from '../icons/left_arrow_gray.png'
import right_arrow_white from '../icons/right_arrow_white.png'
import right_arrow_gray from '../icons/right_arrow_gray.png'
import { ImportedProducts, TProducts } from './Data'

import { useState } from 'react';

function Products() {
    const [isOpen, setIsOpen] = useState(false);
    const [dataIndex, setDataIndex] = useState(0);

    const nextData = () => {
        if (dataIndex < TProducts.length - 1) {
            // alert("Index is: " + dataIndex);
            setDataIndex(dataIndex + 1);
        }
    }
    const previousData = () => {
        setDataIndex(dataIndex - 1);
    }

    const makeItFalse = () => {
        setIsOpen(false);
    };
    const makeItTrue = () => {
        setIsOpen(true);
    };
    return (
        <>
            {/* Mobile */}
            <div className='md:hidden grid justify-items-center mt-[100px]'>
                <h1 className=''>Which one is better For You?</h1>
                <div className='flex justify-center gap-4 my-4'>
                    <button onClick={makeItFalse} className={`${isOpen ? 'border-2 border-[#3A8B6E] text-black' : 'bg-main text-white'} rounded-xl px-4`}>Exported Items</button>
                    <button onClick={makeItTrue} className={`${isOpen ? 'bg-main text-white' : 'border-2 border-[#3A8B6E] text-black'} rounded-xl px-4`}>Imported Items</button>
                </div>
                {/* Exported Items */}
                <div className={`grid justify-items-center bg-black w-full text-white  ${isOpen ? 'hidden' : 'block'}`}>
                    <div className='flex justify-center mt-4 gap-5 w-full'>
                        {/* <h1 className='absolute text-5xl left-10 underline underline-offset-8 top-5'>{TProducts[dataIndex].bigNo}</h1> */}
                        <img className='h-[50px]' src={export_left} />
                        <img className='h-[50px] w-[50px]  rounded-full ' src={eth_logo} />
                        <img className='h-[50px]' src={export_right} />
                    </div>
                    <h1 className='text-[16px] font-bold'>All these items are exported from Ethiopia.</h1>
                    <div className='flex justify-center my-4'>
                        <h1 className='text-[18px] font-bold text-center'>{TProducts[dataIndex].bigNo}</h1>
                        <h1 className='text-[18px] font-bold text-[#3A8B6E] underline underline-offset-8 '>{TProducts[dataIndex].title}</h1>
                    </div>
                    <h1 className=' font-bold text-center text-[14px] my-5 mx-4 '>Here you will describe what <span className='font-black text-[#3A8B6E]'>FOR YOU</span> company offer exactly, also if you want we can make a very hight quality images & videis</h1>
                    <div className='flex justify-between items-center w-full'>

                        <div className='h-[175px] w-[100px] overflow-hidden'>
                            <img className='h-full w-full object-cover rounded-r-xl' src={TProducts[dataIndex].leftImage} />
                        </div>


                        <div className='h-[175px] w-[100px] overflow-hidden'>
                            <img className='h-full w-full object-cover rounded-l-xl' src={TProducts[dataIndex].righImage} />
                        </div>

                    </div>
                    <div className='flex justify-between items-end w-full'>

                        <div className='flex items-center h-20 ml-4'>
                            <button>
                                {dataIndex === TProducts.length - TProducts.length ?
                                    <img className='h-[17.63px] w-[50px]' src={left_arrow_gray} />
                                    :
                                    <img onClick={previousData} className='h-[17.63px] w-[50px]' src={left_arrow_white} />
                                }
                            </button>
                        </div>

                        <div className='h-[81px] w-[201.26px] mt-5 overflow-hidden'>
                            <img className='w-full h-full object-cover rounded-t-lg' src={TProducts[dataIndex].belowImage} />
                        </div>

                        <div className='flex items-center h-20 mr-4'>
                            <button>
                                {dataIndex === TProducts.length - 1 ?
                                    <img className='h-[17.63px] w-[50px]' src={right_arrow_gray} />
                                    :
                                    <img onClick={nextData} className='h-[17.63px] w-[50px]' src={right_arrow_white} />
                                }
                            </button>
                        </div>
                    </div>
                </div>

                {/* Imported Items */}
                <div className={`${!isOpen ? 'hidden' : 'block'}`}>
                    <div className='flex w-screen overflow-auto gap-8 px-4'>

                        {ImportedProducts.map(data => (
                            <>
                                <div className='flex flex-col '>
                                    <div className='w-[210px] h-[258px] '>
                                        <img className="w-full h-full object-cover rounded-[15px]" src={data.product_Img} />
                                    </div>
                                    <h1 className='text-center text-[14px] font-bold'>{data.product_Name}</h1>
                                </div>
                            </>
                        ))}

                    </div>

                </div>

            </div>
            {/* Desktop */}
            <div className='hidden md:grid justify-items-center'>
                <h1>Which one is better For You?</h1>
                <div className='flex justify-center gap-4 my-4'>
                    <button onClick={makeItFalse} className={`${isOpen ? 'border-2 border-[#3A8B6E] text-black' : 'bg-main text-white'} rounded-xl px-4`}>Exported Items</button>
                    <button onClick={makeItTrue} className={`${isOpen ? 'bg-main text-white' : 'border-2 border-[#3A8B6E] text-black'} rounded-xl px-4`}>Imported Items</button>
                </div>
                {/* Exported Items */}
                <div className={`grid justify-items-center bg-black w-full text-white  ${isOpen ? 'hidden' : 'block'}`}>
                    <div className='flex justify-center mt-10 gap-10  w-full'>
                        {/* <h1 className='absolute text-5xl left-10 underline underline-offset-8 top-5'>{TProducts[dataIndex].bigNo}</h1> */}
                        <img className='h-20' src={export_left} />
                        <img className='h-24 w-24  rounded-full ' src={eth_logo} />
                        <img className='h-20' src={export_right} />
                    </div>
                    <h1>All these items are exported from Ethiopia.</h1>
                    <div className='flex justify-center my-4'>
                        <h1 className='text-3xl font-bold'>{TProducts[dataIndex].bigNo}</h1>
                        <h1 className='text-3xl font-bold text-[#3A8B6E] underline underline-offset-8 '>{TProducts[dataIndex].title}</h1>
                    </div>
                    <div className='flex justify-between items-center '>

                        <div className='h-72 w-52 overflow-hidden'>
                            <img className='h-full w-full object-cover rounded-r-xl' src={TProducts[dataIndex].leftImage} />
                        </div>

                        <h1 className=' font-bold text-center w-1/2 '>Here you will describe what <span className='font-black text-[#3A8B6E]'>FOR YOU</span> company offer exactly, also if you want we can make a very hight quality images & videis</h1>

                        <div className='h-72 w-52 overflow-hidden'>
                            <img className='h-full w-full object-cover rounded-l-xl' src={TProducts[dataIndex].righImage} />
                        </div>

                    </div>
                    <div className='flex justify-between items-end w-full'>

                        <div className='flex items-center h-40 ml-5 '>
                            <button>
                                {dataIndex === TProducts.length - TProducts.length ?
                                    <img className='h-10' src={left_arrow_gray} />
                                    :
                                    <img onClick={previousData} className='h-10' src={left_arrow_white} />
                                }
                            </button>
                        </div>

                        <div className='w-96 h-32 overflow-hidden'>
                            <img className='w-full h-full object-cover rounded-t-lg' src={TProducts[dataIndex].belowImage} />
                        </div>

                        <div className='flex items-center h-40 mr-5 '>
                            <button>
                                {dataIndex === TProducts.length - 1 ?
                                    <img className='h-10' src={right_arrow_gray} />
                                    :
                                    <img onClick={nextData} className='h-10' src={right_arrow_white} />
                                }
                            </button>
                        </div>
                    </div>
                </div>
                {/* Imported Items */}
                <div className={`${!isOpen ? 'hidden' : 'block'}`}>
                    <div className='flex flex-col'>
                        {/* 1st Row */}
                        <div className='flex justify-center gap-8'>
                            {/* First Card */}
                            <div className='flex flex-col '>
                                <div className='w-80 h-96 overflow-hidden'>
                                    <img className="w-full h-full object-cover rounded-xl" src={Plastic_raw_material} />
                                </div>
                                <h1 className='text-center font-bold'>Plastic raw material</h1>
                            </div>
                            {/* 2nd Card */}
                            <div className='flex flex-col '>
                                <div className='w-80 h-96 overflow-hidden'>
                                    <img className="w-full h-full object-cover rounded-xl" src={Different_cake_mix_powder} />
                                </div>
                                <h1 className='text-center font-bold'>Different cake mix powder</h1>
                            </div>
                            {/* 3rd Card */}
                            <div className='flex flex-col '>
                                <div className='w-80 h-96 overflow-hidden'>
                                    <img className="w-full h-full object-cover rounded-xl" src={Baking_powder} />
                                </div>
                                <h1 className='text-center font-bold'>Baking powder</h1>
                            </div>

                        </div>

                        {/* 2nd Row */}
                        <div className='flex justify-center gap-8'>
                            {/* First Card */}
                            <div className='flex flex-col '>
                                <div className='w-80 h-96 overflow-hidden'>
                                    <img className="w-full h-full object-cover rounded-xl" src={Ready_made_clothes} />
                                </div>
                                <h1 className='text-center font-bold'>Ready made clothes</h1>
                            </div>
                            {/* 2nd Card */}
                            <div className='flex flex-col '>
                                <div className='w-80 h-96 overflow-hidden'>
                                    <img className="w-full h-full border-2 object-cover rounded-xl" src={Construction_materials} />
                                </div>
                                <h1 className='text-center font-bold'>Construction materials</h1>
                            </div>
                            {/* 3rd Card */}
                            <div className='flex flex-col '>
                                <div className='w-80 h-96 overflow-hidden'>
                                    <img className="w-full h-full object-cover rounded-xl" src={Cosmetics} />
                                </div>
                                <h1 className='text-center font-bold'>Cosmetics</h1>
                            </div>

                        </div>

                        {/* 3rd Row */}
                        <div className='flex justify-center gap-8'>
                            {/* First Card */}
                            <div className='flex flex-col '>
                                <div className='w-80 h-96 overflow-hidden'>
                                    <img className="w-full h-full object-cover rounded-xl" src={Food_stuff} />
                                </div>
                                <h1 className='text-center font-bold'>Food stuff</h1>
                            </div>
                            {/* 2nd Card */}
                            <div className='flex flex-col '>
                                <div className='w-80 h-96 overflow-hidden'>
                                    <img className="w-full h-[450px] border-2 object-cover rounded-xl" src={PPR_and_HDPE_pipe} />
                                </div>
                                <h1 className='text-center font-bold'>PPR and HDPE pipe</h1>
                            </div>
                            {/* 3rd Card */}
                            <div className='flex flex-col '>
                                <div className='w-80 h-96 overflow-hidden'>
                                    <img className="w-full h-full object-cover rounded-xl" src={PP_bags_and_mats} />
                                </div>
                                <h1 className='text-center font-bold'>PP bags and mats</h1>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <hr className='bg-gray-300 w-full h-0.5 my-10' />
        </>
    );
}

export default Products